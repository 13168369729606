export const tags = ["tshirt", "mug", "doormat60x40", "doormat75x50", "doormat90x60", "metalSignHorizontal", "metalSignVertical", "pillow", "totelbag"]

export const labelTypes = {
    1: "Cadeau",
    2: "Document",
    3: "Echantillon commercial",
    4: "Retour de marchandise",
    5: "Autre",
    6: "Vente de marchandises",
}
